import axios from 'axios'
export default{
    methods:{
        async getSignedUrl (data){
            try {
               
                const response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}upload/preSignedUrl`,data)
              return response
            } catch (error) {
                if(error){
                    this.$toasted.show("Error occured", {
                        theme: "toasted-primary",
                        position: "bottom-center",
                        duration: 2000,
                      });
                      throw error;
                }
            }
           
        },
        async UploadSignedURLImage (data , element){
            try {
               
                let signedUrl = data.signedUrl;
                
                let fileType = data.fileType;
               
                let res = await fetch(signedUrl, {
                    method: "PUT",
                    body: element.target.result,
                    headers: {
                      "Content-Type": fileType,
                      "x-amz-acl": "public-read",
                    },
                  });
              return res
            } catch (error) {
                if(error){
                    this.$toasted.show("Error occured", {
                        theme: "toasted-primary",
                        position: "bottom-center",
                        duration: 2000,
                      });
                      throw error;
                }
            }
           
        },
  
    }
}
